import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { navigate, graphql } from 'gatsby'
import { withTranslation, Trans } from 'gatsby-plugin-react-i18next'
import YotpoStars from 'src/components/YotpoStars'
import theme from 'src/utils/styles/theme'
import { GatsbyImage } from 'gatsby-plugin-image'
import Analytics from 'src/utils/Analytics'
import SEO from 'src/components/seo'
import StoreContext from 'src/context/StoreContext'
import { DefaultCalendarSelection, GetCtaDetails } from 'src/utils/FrameProductPage'
import { FormattedCurrency } from 'src/utils/FormattedCurrency'
import Bogo from 'src/components/Bogo'
import SpringSale from 'src/components/SpringSale'
import {
  Header,
  Subheader,
  Centered,
  CustomizationOption,
  FrameProductOptions,
} from 'src/components/FrameProductForm/styles'

import { Description } from 'src/components/Bogo/styles'
import { Anchor } from '../../components/ReviewsSection/styles'
import Heart from '../../images/calendar/heart.svg'

import {
  ShopifyDescription,
  Thumbnail,
  ThumbnailRow,
  ThumbnailButton,
  Section,
  Container,
  ProductGridLeft,
  ProductGridRight,
  Grid,
  ProductImage,
  WaitlistForm,
  ArrowContainer,
  LeftArrow,
  RightArrow,
} from '../FrameProductPage/styles'

import {
  StyleText,
  SubLabel,
  CalMaxIcon,
  StyleOptionsContainer,
  SelectionOption,
  StyleDescription,
  LimitedAvailable,
  Badge,
  HeaderContainer,
  PickPriceContainer,
  GiftModeImage,
  CalFeatureRow,
  CalFeature,
  CalFeatureHeading,
  CalFeatureText,
  IconContainer,
  PlusPlanIncludes,
  ProductSelectionBoxWrapper,
  ProductSelectionBox,
  ProductSelectionPricePoint,
  ClickableProductSelectionPricePoint,
  ClickableProductSelectionBox,
  ProductSelectionHeaderRow,
  ProductSelectionHeader,
  RadioIconContainer,
  MostPopular,
  PriceAndExplainerContainer,
  OneTimePurchase,
  SaveInfo,
  PlanHeading,
  DropDownContainer,
  DropDownContainerOptions,
  DropDownLabel,
  DropDownLabelOption,
  DropDownImageContainer,
  DropDownIconContainer,
  Disclosure,
  ThumbnailVideo,
  CalendarVideoThumbnail,
  VideoContainer,
  BogoContainer,
} from './styles'
import ColorSelectionItem from '../../components/ColorSelectionItem'
import ReviewsSection from '../../components/ReviewsSection'
import { IS_FOREIGN_COUNTRY, IN_CA, IN_AU, IN_UK, currencyCode, IN_EU } from '../../utils/constants'
import CalendarSyncs from '../../components/CalendarSyncs'
import { CAL_PLUS_FEATURES, PRODUCT_DROPDOWN_LABELS } from './constants'
// import DiscountBanner from '../../components/DiscountBanner'

import FAQs from '../../components/FAQs'
import ShippingDetails from '../../components/ShippingDetails'
import AddToCartButton from '../../components/AddToCartButton'

import calProductVideo from '../../images/cal-pdp-video-thumbnail.webp'
import { AMAZON_LINKS } from '../../components/FrameProductForm/constants'

const defaultLanguage = process.env.GATSBY_I18N_DEFAULT_LANGUAGE

class CalendarProductPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      shownImageIndex: 0,
      size: 15,
      color: 'Natural Aluminum',
      style: 'Shadow Box',
      isPlusProduct: true,
      submitting: false,
      showDropdown: false,
      showVideo: false,
      isScrollable: false,
      Cal10Offer: false,
      Cal15Offer: false,
      checked: false,
    }
    this.handleSizeSelection = this.handleSizeSelection.bind(this)
    this.handleStyleSelection = this.handleStyleSelection.bind(this)
    this.handleColorSelection = this.handleColorSelection.bind(this)
    this.setShownImageIndex = this.setShownImageIndex.bind(this)
    this.handleAddToCart = this.handleAddToCart.bind(this)
    this.setShowDropdown = this.setShowDropdown.bind(this)
    this.handlePlusProduct = this.handlePlusProduct.bind(this)
    this.noplusSelectionRef = React.createRef()
    this.scrollableThumbnailContainerRef = React.createRef()
    this.handleOfferSelection = this.handleOfferSelection.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
  }

  componentDidMount() {
    this.checkIfScrollable()
    const {
      data: { product },
      pageContext: { calMaxShadowBoxNaturalAluminum },
    } = this.props

    const { size, color, style } = this.state
    let sku
    let handle
    const isExactMatch = window.location.href
      .split('/')
      .some((segment) => segment === 'skylight-calendar-max')
    if (isExactMatch) {
      sku = calMaxShadowBoxNaturalAluminum.data.shopifyProduct.variants[0].sku
      handle = calMaxShadowBoxNaturalAluminum.data.shopifyProduct.handle
    } else {
      sku = product.variants[0].sku
      handle = product.handle
    }
    if (handle === 'the-skylight-calendar-pro-149') {
      this.setState({ size: 10, isPlusProduct: handle === 'the-skylight-calendar-pro-149' })
    }
    setTimeout(() => {
      Analytics.track(`Viewed Calendar Product Page`, { sku })
    }, 3000)

    if (handle.includes('max')) {
      const designDefault = DefaultCalendarSelection(handle, { size, color, style })
      this.setState(designDefault)
    }
    let url = window.location.href
    if (url.endsWith('/')) {
      url = url.slice(0, -1)
    }
    const urlParams = new URLSearchParams(new URL(url).search)
    const utmCampaign = urlParams.get('utm_campaign')
    const utmTerm = urlParams.get('utm_term')

    if (utmCampaign === 'gma' || utmTerm === 'gma') {
      const { updateBanner } = this.context
      const promoCode = 'GMA'
      const channel = 'GMA'
      const amount = '50'
      updateBanner(promoCode, channel, amount)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isPlusProduct } = this.state

    if (prevState.isPlusProduct !== isPlusProduct && !isPlusProduct) {
      if (this.noplusSelectionRef.current) {
        const elementPosition =
          this.noplusSelectionRef.current.getBoundingClientRect().top + window.scrollY
        const bannerElement = document.querySelector('.banner')
        const scrollOffset = bannerElement ? bannerElement.offsetHeight + 80 : 80
        window.scrollTo({
          top: elementPosition - scrollOffset,
          behavior: 'smooth',
        })
      }
    }
  }

  handleSizeSelection(size) {
    this.setState({ size })
  }

  handleStyleSelection(style) {
    const { size } = this.state
    const color = style === 'Classic' ? 'White' : 'Natural Aluminum'
    this.setState({ style, color })
    Analytics.track(`Viewed ${size}in Cal ${style} ${color} Toggle Product Page`)
  }

  handleColorSelection(color) {
    const { size, style } = this.state
    this.setState({ color })
    Analytics.track(`Viewed ${size}in Cal ${style} ${color} Toggle Product Page`)
  }

  handlePlusProduct(bool) {
    this.setState({ isPlusProduct: bool })
  }

  handleOfferSelection(size, bool) {
    const key = `${size}Offer`
    this.setState({ [key]: bool })
  }

  handleCheckboxChange(bool) {
    this.setState({ checked: bool })
  }

  async handleAddToCart(productInfo) {
    const { addToCart } = this.context
    const { submitting, size, color, style, Cal10Offer, Cal15Offer } = this.state
    const variantId = productInfo.shopifyId
    const id = variantId.split('/').pop()
    const pushToDataLayer = () => {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        event: 'addToCart',
        ecommerce: {
          currencyCode,
          add: {
            products: [
              {
                id,
                name: productInfo.name,
                category: 'Calendar',
                price: productInfo.price,
                quantity: 1,
              },
            ],
          },
        },
      })
    }
    if (!submitting) {
      this.setState({ submitting: true })
      Analytics.track(`Added Calendar to Cart`)
      const baseEvent = `Added ${size}in Cal`
      const message =
        size === 10 || size === 15
          ? `${baseEvent} to Cart`
          : `${baseEvent} ${style} ${color} to Cart`
      Analytics.track(message)
      const items = [{ variantId, quantity: 1 }]
      let frameBlackShopifyId
      let calendar10ShopifyId
      let calendar15ShopifyId

      if (!IS_FOREIGN_COUNTRY) {
        ;({
          pageContext: {
            frameBlack: {
              data: {
                shopifyProduct: { variants: [{ shopifyId: frameBlackShopifyId } = {}] = [] } = {},
              } = {},
            } = {},
            calendar10: {
              data: {
                shopifyProduct: { variants: [{ shopifyId: calendar10ShopifyId } = {}] = [] } = {},
              } = {},
            } = {},
            calendar15: {
              data: {
                shopifyProduct: { variants: [{ shopifyId: calendar15ShopifyId } = {}] = [] } = {},
              } = {},
            } = {},
          },
        } = this.props)
      } else if (IN_UK || IN_AU || IN_CA || IN_EU) {
        ;({
          pageContext: {
            frameBlack: {
              data: {
                shopifyProduct: { variants: [{ shopifyId: frameBlackShopifyId } = {}] = [] } = {},
              } = {},
            } = {},
          },
        } = this.props)
      }
      const { checked } = this.state
      if (Cal10Offer) {
        items.push({ variantId: calendar10ShopifyId, quantity: 1 })
        Analytics.track(`Added Calendar 10" BOGO Deal to Cart`)
      }
      if (Cal15Offer) {
        items.push({ variantId: calendar15ShopifyId, quantity: 1 })
        Analytics.track(`Added Calendar 15" BOGO Deal to Cart`)
      }
      if (checked) {
        items.push({ variantId: frameBlackShopifyId, quantity: 1 })
        Analytics.track(`Added Frame BOGO Deal to Cart`)
      }
      const resp = await addToCart(items)
      if (resp && resp.error) {
        this.setSubmitting(false)
        alert(resp.error)
      } else {
        pushToDataLayer(variantId, productInfo)
        navigate('/cart/')
      }
    }
  }

  setShowDropdown(showDropdown) {
    this.setState({ showDropdown })
  }

  setShownImageIndex(shownImageIndex) {
    this.setState({ shownImageIndex })
  }

  checkIfScrollable() {
    const scrollableElement = this.scrollableThumbnailContainerRef.current
    if (scrollableElement) {
      const newisScrollable = scrollableElement.scrollWidth > scrollableElement.clientWidth
      const { isScrollable } = this.state
      if (newisScrollable !== isScrollable) {
        this.setState({ isScrollable: newisScrollable })
      }
    }
  }

  scrollLeft() {
    if (this.scrollableThumbnailContainerRef.current) {
      this.scrollableThumbnailContainerRef.current.scrollBy({ left: -130, behavior: 'smooth' })
    }
  }

  scrollRight() {
    if (this.scrollableThumbnailContainerRef.current) {
      this.scrollableThumbnailContainerRef.current.scrollBy({ left: 130, behavior: 'smooth' })
    }
  }

  render() {
    const {
      shownImageIndex,
      showVideo,
      largerDevice,
      isPlusProduct,
      showDropdown,
      size,
      color,
      style,
      isScrollable,
      Cal10Offer,
      Cal15Offer,
      checked,
    } = this.state
    const { data, pageContext, t } = this.props
    const {
      radioFull,
      radioEmpty,
      dropdownicon,
      dropupicon,
      customizedScreensaver,
      mealPlanning,
      magicImport,
      calMaxClassicBlackIcon,
      calMaxClassicWhiteIcon,
      calMaxShadowBoxAluminumIcon,
      calMaxShadowBoxCharcoalIcon,
      contentfulCTAOverrides,
    } = data

    let { product, badge } = data

    const {
      calendar10,
      calendar15,
      calMaxShadowBoxNaturalAluminum,
      calMaxShadowBoxCharcoal,
      calMaxClassicBlack,
      calMaxClassicWhite,
      calendarWithPlus,
      calendarWith15Plus,
      calMaxShadowBoxNaturalAluminumWithPlus,
      calMaxShadowBoxCharcoalWithPlus,
      calMaxClassicBlackWithPlus,
      calMaxClassicWhiteWithPlus,
      frameBlack,
    } = pageContext

    if (!product) {
      product = calMaxShadowBoxNaturalAluminum.data.shopifyProduct
    }

    let { images } = product
    let productDescription = product
    let productMap
    const getProductKey = (plusProduct = isPlusProduct) => {
      let key = `${size}.${plusProduct ? 'isPlusProduct' : 'withoutPlus'}`
      if (size === 27) {
        key = `${key}.${style}.${color}`
      }
      return key
    }
    let calProduct
    let calPlusProducts
    if (!IS_FOREIGN_COUNTRY) {
      productMap = {
        '15.withoutPlus': calendar15.data.shopifyProduct,
        '10.withoutPlus': calendar10.data.shopifyProduct,
        '27.withoutPlus.Classic.White': calMaxClassicWhite.data.shopifyProduct,
        '27.withoutPlus.Shadow Box.Natural Aluminum':
          calMaxShadowBoxNaturalAluminum.data.shopifyProduct,
        '27.withoutPlus.Classic.Black': calMaxClassicBlack.data.shopifyProduct,
        '27.withoutPlus.Shadow Box.Charcoal': calMaxShadowBoxCharcoal.data.shopifyProduct,
        '15.isPlusProduct': calendarWith15Plus.data.shopifyProduct,
        '10.isPlusProduct': calendarWithPlus.data.shopifyProduct,
        '27.isPlusProduct.Classic.White': calMaxClassicWhiteWithPlus.data.shopifyProduct,
        '27.isPlusProduct.Shadow Box.Natural Aluminum':
          calMaxShadowBoxNaturalAluminumWithPlus.data.shopifyProduct,
        '27.isPlusProduct.Classic.Black': calMaxClassicBlackWithPlus.data.shopifyProduct,
        '27.isPlusProduct.Shadow Box.Charcoal': calMaxShadowBoxCharcoalWithPlus.data.shopifyProduct,
      }
      const imageKey = getProductKey()
      images = productMap[imageKey].images || images
      productDescription = productMap[getProductKey()]
      const calKey = getProductKey(false) // for regular product
      const calPlusKey = getProductKey(true) // for plus product
      ;[calProduct] = productMap[calKey].variants
      ;[calPlusProducts] = productMap[calPlusKey].variants
    }
    if (IN_CA || IN_AU || IN_UK) {
      ;[calProduct] = calendar15.data.shopifyProduct.variants
      ;[calPlusProducts] = calendarWith15Plus.data.shopifyProduct.variants
    }

    const shownImage = images[shownImageIndex]
    const TITLE_OVERRIDES = {
      'en-ca': 'The Skylight Calendar 15"',
      'en-au': 'The Skylight Calendar 15"',
      'en-gb': 'The Skylight Calendar 15"',
    }
    const isCalMax = size === 27

    const productName = TITLE_OVERRIDES[defaultLanguage]
      ? TITLE_OVERRIDES[defaultLanguage]
      : isCalMax
      ? `Calendar Max ${style} ${color}`
      : `The Skylight Calendar${size === 15 ? ` 15"` : ''}`
    const productInfo = {
      calendar: { ...calProduct, name: productName },
      withPlus: { ...calPlusProducts, name: `${productName} with Plus Plan` },
    }

    const calPlusFeatureIcons = { mealPlanning, customizedScreensaver, magicImport }
    const badgeList = badge.edges[0].node.show_pdp_badge
    const includesSizeAndCalendar = (list) => {
      const searchString = `${size}" Calendar`
      return list.some((item) => item.includes(searchString))
    }
    const lang = defaultLanguage.replace('-', '_')

    const displayBadge = !IS_FOREIGN_COUNTRY && includesSizeAndCalendar(badgeList)
    const ctaDetails = GetCtaDetails(lang, true, size, contentfulCTAOverrides.edges[0].node, color)
    const { CTA_VALUE, isOutOfStock, isPreorder } = ctaDetails

    return (
      <>
        <SEO
          title={t('Skylight Calendar | Smart Family Calendar')}
          description={`Skylight Calendar is a smart, touchscreen wall calendar that displays your family's important events and chores.`}
          imagePath={product.images[0].gatsbyImageData.images.fallback.src}
          path={`/products/${product.handle}/`}
        />
        <Section>
          <Container>
            <Grid>
              <ProductGridLeft>
                {showVideo === true ? (
                  <VideoContainer>
                    <iframe
                      title="Skylight Calendar Demo"
                      width="100%"
                      height="100%"
                      src="https://cdn.shopify.com/videos/c/o/v/4443230e58df472c841fa354b70242b7.mp4"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </VideoContainer>
                ) : (
                  <ProductImage
                    image={shownImage.gatsbyImageData}
                    key={`${size}-${color}-${isPlusProduct}-${shownImage.id}`}
                    alt={product.title}
                    className={largerDevice ? 'zoom' : 'standard'}
                  />
                )}
                {displayBadge && (
                  <GiftModeImage>
                    <GatsbyImage
                      image={badge.edges[0].node.calendar_badge.gatsbyImageData}
                      alt="Calendar Product Page Badge"
                    />
                  </GiftModeImage>
                )}
                <ArrowContainer>
                  {isScrollable && (
                    <LeftArrow type="button" onClick={() => this.scrollLeft()}>
                      ‹
                    </LeftArrow>
                  )}
                  <ThumbnailRow ref={this.scrollableThumbnailContainerRef} start={isScrollable}>
                    {images.map((image, index) => (
                      <ThumbnailButton
                        key={`thumbnail-${size}-${color}-${isPlusProduct}-${image.id}-${index}`}
                        type="button"
                        onClick={() => {
                          this.setShownImageIndex(index)
                          this.setState({ showVideo: false })
                        }}
                      >
                        <Thumbnail
                          $calendar
                          image={image.gatsbyImageData}
                          alt={`${product.title} Thumbnail ${index}`}
                        />
                      </ThumbnailButton>
                    ))}
                    {!isCalMax && (
                      <ThumbnailButton
                        type="button"
                        onClick={() => this.setState({ showVideo: true })}
                      >
                        <ThumbnailVideo>
                          <CalendarVideoThumbnail
                            src={calProductVideo}
                            alt="Introducting Skylight Calendar"
                          />
                        </ThumbnailVideo>
                      </ThumbnailButton>
                    )}
                  </ThumbnailRow>
                  {isScrollable && (
                    <RightArrow type="button" onClick={() => this.scrollRight()}>
                      ›
                    </RightArrow>
                  )}
                </ArrowContainer>
              </ProductGridLeft>
              <ProductGridRight>
                <>
                  <Centered>
                    <YotpoStars color={theme.gold} size={18} productId="6863425175604" />
                  </Centered>
                  <HeaderContainer>
                    <Header>
                      <Trans>
                        {TITLE_OVERRIDES[defaultLanguage]
                          ? TITLE_OVERRIDES[defaultLanguage]
                          : isCalMax
                          ? 'Calendar Max'
                          : 'The Skylight Calendar'}
                      </Trans>
                    </Header>
                    {isCalMax && <Badge>NEW</Badge>}
                  </HeaderContainer>
                  {!IN_CA && !IN_AU && !IN_UK && (
                    <CustomizationOption>
                      <Subheader>
                        <Trans>Select your Calendar size</Trans>
                      </Subheader>
                      <DropDownContainer onClick={() => this.setShowDropdown(!showDropdown)}>
                        <DropDownLabel>
                          <p>{PRODUCT_DROPDOWN_LABELS[size].label}</p>
                        </DropDownLabel>
                        <DropDownImageContainer>
                          <DropDownIconContainer>
                            <GatsbyImage
                              image={
                                showDropdown
                                  ? dropupicon.childImageSharp.gatsbyImageData
                                  : dropdownicon.childImageSharp.gatsbyImageData
                              }
                              alt="Drop Down Arrow"
                            />
                          </DropDownIconContainer>
                        </DropDownImageContainer>
                      </DropDownContainer>
                    </CustomizationOption>
                  )}
                  {showDropdown && (
                    <DropDownContainerOptions margin>
                      {Object.entries(PRODUCT_DROPDOWN_LABELS).map(([key, details]) => {
                        if (parseInt(size, 10) !== parseInt(key, 10)) {
                          return (
                            <DropDownLabelOption
                              key={`${size}-${key}-dropdown`}
                              onClick={() => {
                                this.handleSizeSelection(parseInt(key, 10))
                                this.setShowDropdown(false)
                                this.setShownImageIndex(0)
                                Analytics.track(
                                  `Viewed ${parseInt(key, 10)}in Cal Toggle Product Page`
                                )
                              }}
                            >
                              {details.label}
                            </DropDownLabelOption>
                          )
                        }
                        return null
                      })}
                    </DropDownContainerOptions>
                  )}
                  {isCalMax && (
                    <>
                      <SubLabel oos>
                        <Trans>Now Shipping Immediately!</Trans>
                      </SubLabel>
                      {!isOutOfStock && (
                        <LimitedAvailable open={showDropdown}>
                          Please allow 5-7 days for standard shipping and 3-5 days for expedited
                          shipping
                        </LimitedAvailable>
                      )}
                      <SubLabel>
                        <Trans>Select a style</Trans>
                      </SubLabel>
                      <StyleOptionsContainer>
                        <SelectionOption
                          selected={style === 'Shadow Box'}
                          onClick={() => {
                            this.handleStyleSelection('Shadow Box')
                            this.setShownImageIndex(0)
                          }}
                        >
                          <CalMaxIcon>
                            <GatsbyImage
                              image={
                                color === 'Charcoal'
                                  ? calMaxShadowBoxCharcoalIcon.childImageSharp.gatsbyImageData
                                  : calMaxShadowBoxAluminumIcon.childImageSharp.gatsbyImageData
                              }
                              alt="Save with Plus"
                            />
                          </CalMaxIcon>
                          <StyleText>Shadow Box</StyleText>
                          <StyleText blue>
                            $
                            {style === 'Shadow Box' && isPlusProduct
                              ? productInfo.withPlus.price
                              : style === 'Shadow Box'
                              ? productInfo.calendar.price
                              : '599.99'}
                          </StyleText>
                          <StyleDescription>
                            Our favorite. Encased in 100% aluminum frame.
                          </StyleDescription>
                        </SelectionOption>
                        <SelectionOption
                          selected={style === 'Classic'}
                          onClick={() => {
                            this.handleStyleSelection('Classic')
                            this.setShownImageIndex(0)
                          }}
                        >
                          <CalMaxIcon>
                            <GatsbyImage
                              image={
                                color === 'Black'
                                  ? calMaxClassicBlackIcon.childImageSharp.gatsbyImageData
                                  : calMaxClassicWhiteIcon.childImageSharp.gatsbyImageData
                              }
                              alt="Save with Plus"
                            />
                          </CalMaxIcon>
                          <StyleText>Classic</StyleText>
                          <StyleText blue>
                            $
                            {style === 'Classic' && isPlusProduct
                              ? productInfo.withPlus.price
                              : style === 'Classic'
                              ? productInfo.calendar.price
                              : '569.99'}
                          </StyleText>
                          <StyleDescription>
                            Timeless and contemporary plastic frame.
                          </StyleDescription>
                        </SelectionOption>
                      </StyleOptionsContainer>
                      <SubLabel>
                        <Trans>Select a {style === 'Classic' ? 'color' : 'finish'}</Trans>
                      </SubLabel>
                      <FrameProductOptions>
                        {style === 'Classic' ? (
                          <>
                            <ColorSelectionItem
                              variant="White"
                              onSelectColor={() => {
                                this.handleColorSelection('White')
                                this.setShownImageIndex(0)
                              }}
                              isOutOfStock={
                                contentfulCTAOverrides.edges[0].node.en_27_white_calendar ===
                                'Out of Stock'
                              }
                              isSelected={color === 'White'}
                            />
                            <ColorSelectionItem
                              variant="Black"
                              onSelectColor={() => {
                                this.handleColorSelection('Black')
                                this.setShownImageIndex(0)
                              }}
                              isOutOfStock={
                                contentfulCTAOverrides.edges[0].node.en_27_black_calendar ===
                                'Out of Stock'
                              }
                              isSelected={color === 'Black'}
                            />
                          </>
                        ) : (
                          <>
                            <ColorSelectionItem
                              variant="Natural Aluminum"
                              onSelectColor={() => {
                                this.handleColorSelection('Natural Aluminum')
                                this.setShownImageIndex(0)
                              }}
                              isOutOfStock={
                                contentfulCTAOverrides.edges[0].node.en_27_black_aluminum ===
                                'Out of Stock'
                              }
                              isSelected={color === 'Natural Aluminum'}
                            />
                            <ColorSelectionItem
                              variant="Charcoal"
                              onSelectColor={() => {
                                this.handleColorSelection('Charcoal')
                                this.setShownImageIndex(0)
                              }}
                              isOutOfStock={
                                contentfulCTAOverrides.edges[0].node.en_27_black_charcoal ===
                                'Out of Stock'
                              }
                              isSelected={color === 'Charcoal'}
                            />
                          </>
                        )}
                      </FrameProductOptions>
                    </>
                  )}
                  <PickPriceContainer topMargin={isCalMax}>
                    <Subheader>
                      <Trans>Pick your price</Trans>
                    </Subheader>
                    {!isPlusProduct ? (
                      <>
                        <ProductSelectionBoxWrapper>
                          <MostPopular>
                            <Trans>Most Popular</Trans>
                          </MostPopular>
                          <ClickableProductSelectionBox
                            style={{ marginTop: -16 }}
                            onClick={() => {
                              this.handlePlusProduct(true)
                              this.setShownImageIndex(0)
                            }}
                          >
                            <ProductSelectionHeaderRow>
                              <ProductSelectionHeader>
                                <RadioIconContainer>
                                  <GatsbyImage
                                    image={radioEmpty.childImageSharp.gatsbyImageData}
                                    alt="Save with Plus"
                                  />
                                </RadioIconContainer>
                                <SaveInfo calendar>
                                  <Trans
                                    i18nKey="Save with Plus Plan"
                                    values={{
                                      priceSaving:
                                        productInfo.calendar.price - productInfo.withPlus.price,
                                    }}
                                  >
                                    Save {productInfo.calendar.price - productInfo.withPlus.price}{' '}
                                    with Plus Plan
                                  </Trans>
                                </SaveInfo>
                              </ProductSelectionHeader>
                              <PriceAndExplainerContainer>
                                <ClickableProductSelectionPricePoint>
                                  <>
                                    <strike>{FormattedCurrency(productInfo.calendar.price)}</strike>{' '}
                                    {FormattedCurrency(productInfo.withPlus.price)}
                                  </>
                                </ClickableProductSelectionPricePoint>
                              </PriceAndExplainerContainer>
                            </ProductSelectionHeaderRow>
                          </ClickableProductSelectionBox>
                        </ProductSelectionBoxWrapper>
                        <ProductSelectionBox ref={this.noplusSelectionRef}>
                          <ProductSelectionHeaderRow>
                            <ProductSelectionHeader>
                              <RadioIconContainer>
                                <GatsbyImage
                                  image={radioFull.childImageSharp.gatsbyImageData}
                                  alt="Save with Plus"
                                />
                              </RadioIconContainer>
                              <PlanHeading>
                                <Trans>Pay full price with no plan</Trans>
                              </PlanHeading>
                            </ProductSelectionHeader>
                            <PriceAndExplainerContainer>
                              <ProductSelectionPricePoint>
                                {FormattedCurrency(productInfo.calendar.price)}
                              </ProductSelectionPricePoint>
                              <OneTimePurchase>
                                <Trans>One-Time Purchase</Trans>
                              </OneTimePurchase>
                            </PriceAndExplainerContainer>
                          </ProductSelectionHeaderRow>
                          <p>
                            <Trans>
                              Skip the Plus Plan and pay more for your Calendar. Without Plus:
                            </Trans>
                          </p>
                          <ul>
                            <li>
                              <Trans>
                                You can still use all of Calendar's other features for free forever.
                              </Trans>
                            </li>
                            <li>
                              <Trans>
                                You will not be able to display photos on your Calendar or use Magic
                                Import or Meal Planning.
                              </Trans>
                            </li>
                          </ul>
                          <AddToCartButton
                            label={t(CTA_VALUE)}
                            // adding={submitting}
                            oos={isOutOfStock}
                            handleClick={() => this.handleAddToCart(productInfo.calendar)}
                          />
                        </ProductSelectionBox>
                      </>
                    ) : (
                      <>
                        <ProductSelectionBoxWrapper>
                          <MostPopular>
                            <Trans>Most Popular</Trans>
                          </MostPopular>
                          <ProductSelectionBox style={{ marginTop: -16 }}>
                            <ProductSelectionHeaderRow>
                              <ProductSelectionHeader>
                                <RadioIconContainer>
                                  <GatsbyImage
                                    image={radioFull.childImageSharp.gatsbyImageData}
                                    alt="Save with Plus"
                                  />
                                </RadioIconContainer>
                                <SaveInfo calendar>
                                  <Trans
                                    i18nKey="Save with Plus Plan"
                                    values={{
                                      priceSaving:
                                        productInfo.calendar.price - productInfo.withPlus.price,
                                    }}
                                  >
                                    Save ${productInfo.calendar.price - productInfo.withPlus.price}{' '}
                                    with Plus Plan
                                  </Trans>
                                </SaveInfo>
                              </ProductSelectionHeader>
                              <PriceAndExplainerContainer>
                                <ProductSelectionPricePoint>
                                  <>
                                    <strike>{FormattedCurrency(productInfo.calendar.price)}</strike>{' '}
                                    {FormattedCurrency(productInfo.withPlus.price)}
                                  </>
                                </ProductSelectionPricePoint>
                                <OneTimePurchase cal>
                                  {size === 27 ? (
                                    <Trans>
                                      ${productInfo.withPlus.price} One-Time Purchase + $39 Annual
                                      Plus plan (charged after delivery)
                                    </Trans>
                                  ) : (
                                    <Trans>
                                      {FormattedCurrency(productInfo.withPlus.price)} One-Time
                                      Purchase + One Free Month Of Plus
                                    </Trans>
                                  )}
                                </OneTimePurchase>
                              </PriceAndExplainerContainer>
                            </ProductSelectionHeaderRow>
                            {size === 27 ? (
                              <PlusPlanIncludes underline>
                                Get more with Skylight Plus ($39/year)
                              </PlusPlanIncludes>
                            ) : (
                              <PlusPlanIncludes>Plus Plan Includes:</PlusPlanIncludes>
                            )}

                            <CalFeatureRow>
                              {Object.keys(CAL_PLUS_FEATURES).map((key) => {
                                const feature = CAL_PLUS_FEATURES[key]
                                const icon = calPlusFeatureIcons[key]
                                return (
                                  <CalFeature key={key}>
                                    <IconContainer>
                                      <GatsbyImage
                                        image={icon.childImageSharp.gatsbyImageData}
                                        alt={feature.heading}
                                      />
                                    </IconContainer>
                                    <CalFeatureHeading>{feature.heading}</CalFeatureHeading>
                                    <CalFeatureText>{feature.text}</CalFeatureText>
                                  </CalFeature>
                                )
                              })}
                            </CalFeatureRow>
                            {size === 27 ? (
                              <div />
                            ) : (
                              <p style={{ marginLeft: 0, textAlign: 'center' }}>
                                <Trans components={{ bold: <strong /> }}>
                                  Your first month is free, then Plus is <strong>$39/year</strong>{' '}
                                  (that's just 11c/day)!
                                </Trans>
                              </p>
                            )}
                            <AddToCartButton
                              label={t(CTA_VALUE)}
                              // adding={submitting}
                              oos={isOutOfStock}
                              handleClick={() => this.handleAddToCart(productInfo.withPlus)}
                            />
                            {size === 27 && (
                              <CalFeatureText style={{ textAlign: 'center' }}>
                                <Trans>
                                  Pay {style === 'Shadow Box' ? '$599.99' : '$569.99'} today. You
                                  will be charged the $39 for Calendar Plus one month after
                                  delivery.
                                </Trans>
                              </CalFeatureText>
                            )}
                          </ProductSelectionBox>
                        </ProductSelectionBoxWrapper>
                        <ClickableProductSelectionBox
                          onClick={() => {
                            this.handlePlusProduct(false)
                            this.setShownImageIndex(0)
                          }}
                        >
                          <ProductSelectionHeaderRow>
                            <ProductSelectionHeader>
                              <RadioIconContainer>
                                <GatsbyImage
                                  image={radioEmpty.childImageSharp.gatsbyImageData}
                                  alt="Save with Plus"
                                />
                              </RadioIconContainer>
                              <PlanHeading>
                                <Trans>Pay full price with no plan</Trans>
                              </PlanHeading>
                            </ProductSelectionHeader>
                            <PriceAndExplainerContainer>
                              <ClickableProductSelectionPricePoint>
                                <Trans>{FormattedCurrency(productInfo.calendar.price)} </Trans>
                              </ClickableProductSelectionPricePoint>
                              <OneTimePurchase>
                                <Trans>One-Time Purchase</Trans>
                              </OneTimePurchase>
                            </PriceAndExplainerContainer>
                          </ProductSelectionHeaderRow>
                        </ClickableProductSelectionBox>
                      </>
                    )}
                  </PickPriceContainer>
                  {!IS_FOREIGN_COUNTRY && !isOutOfStock && !isPreorder && <ShippingDetails />}
                </>
                <CalendarSyncs page="calendar-product" padding="0px" />
                <ShopifyDescription
                  dangerouslySetInnerHTML={{ __html: productDescription.descriptionHtml }}
                />
              </ProductGridRight>
            </Grid>
          </Container>
          <Anchor id="review" />
          <ReviewsSection productId="6863425175604" name="The Skylight Calendar" />
          <FAQs page="calendarProductPage" />
        </Section>
      </>
    )
  }
}

CalendarProductPage.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
  t: PropTypes.func,
}
export const query = graphql`
  query ($handle: String!, $language: String!) {
    product: shopifyProduct(handle: { eq: $handle }) {
      id
      title
      handle
      productType
      description
      descriptionHtml
      shopifyId
      variants {
        id
        title
        price
        shopifyId
        sku
        product {
          images {
            id
            gatsbyImageData(width: 700, layout: FULL_WIDTH)
          }
          description
        }
      }
      images {
        id
        gatsbyImageData
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["common", "frame-product"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    radioFull: file(relativePath: { eq: "icons/radio-full.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    radioEmpty: file(relativePath: { eq: "icons/radio-empty.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    dropdownicon: file(relativePath: { eq: "calendar/down-arrow.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE)
      }
    }
    dropupicon: file(relativePath: { eq: "calendar/up-arrow.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE)
      }
    }
    customizedScreensaver: file(relativePath: { eq: "photo-screensaver-square.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    mealPlanning: file(relativePath: { eq: "meal.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    magicImport: file(relativePath: { eq: "magic-import.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    calMaxClassicBlackIcon: file(relativePath: { eq: "calmax/classic-black-cal-max.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    calMaxClassicWhiteIcon: file(relativePath: { eq: "calmax/classic-white-cal-max.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    calMaxShadowBoxAluminumIcon: file(relativePath: { eq: "calmax/shadow-box-aluminum.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    calMaxShadowBoxCharcoalIcon: file(relativePath: { eq: "calmax/shadow-box-charcoal.webp" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, layout: FULL_WIDTH, placeholder: NONE)
      }
    }
    badge: allContentfulFrameCalendarProductPageBadge {
      edges {
        node {
          calendar_badge {
            gatsbyImageData(placeholder: NONE)
          }
          show_pdp_badge
        }
      }
    }
    contentfulCTAOverrides: allContentfulCtaCopyOnFrameAndCalendarPdp {
      edges {
        node {
          en_10_calendar
          en_15_calendar
          en_27_aluminum_calendar
          en_27_charcoal_calendar
          en_27_white_calendar
          en_27_black_calendar
          en_ca_15_calendar
          en_gb_15_calendar
          en_au_15_calendar
        }
      }
    }
  }
`

CalendarProductPage.contextType = StoreContext

export default withTranslation()(CalendarProductPage)
